import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import cn from 'classnames'
import { getContentFromFirestore } from "../services/contentFirebase";
import { CardItem, CardTiradas, ContentYesOrNot } from "../components/cards"
import { BannerDownload, ErrorLoadCard, GooglePlayDownload, LinkWrapper, ZodiacName } from "../components/content"
import { getValidCards, getZodiac } from "../utils/util"
import { cardsAvailable } from "../params/params"

function Es() {
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await getContentFromFirestore();
      setLoading(false);
      setContent(data);
    }

    fetchData()
      .catch(console.error);
  }, [])

  if (loading || !content) {
    return <div style={{ textAlign: "center", marginTop: "2rem" }}>
      <img src="/assets/loading.svg" alt="loading" width={80} height="auto" />
    </div>
  }
  
  // From URL
  const { tematic, card, zodiac, question, tirada, date } = Object.fromEntries([...searchParams])

  // From content file
  const { labels, cards, tematics, zodiac: contentZodiac, tagCards, combinations } = content

  // Get cards and validations
  const optionsCard = cardsAvailable[+tematic];
  const { displayCards, totalYes, totalNo, hasErrorCard } = getValidCards(card, cards, optionsCard, tirada)
  const { displayZodiac, hasErrorZodiac } = getZodiac(zodiac, contentZodiac)
  const titleTematic = tematics?.[tematic]

  if (optionsCard?.isShowContentCombination) {
    if (combinations && combinations[tematic] && !combinations[tematic][card]?.active) {
      return <ErrorLoadCard labels={labels} />
    }
  }

  if (hasErrorCard || hasErrorZodiac) {
    return <ErrorLoadCard labels={labels} />
  }

  return (
    <>
      <div className="layout">

        {/* 1. HEADER */}
        {/* ================================================ */}
        <div className={cn({ 'isPurple': !!optionsCard?.isPurpleTirada })}>
          <LinkWrapper link={labels.downloadLink}>
            <GooglePlayDownload />
            <BannerDownload props={labels.downloadLabel} />
          </LinkWrapper>
        </div>

        {/* 2. TIRADAS */}
        {/* ================================================ */}
        {optionsCard?.isPurpleTirada ? (
          <div className={cn('tematicBackground', { 'isPurple': !!optionsCard?.isPurpleTirada })}>
            <LinkWrapper link={labels.downloadLink}>
              <h4 className='center'>{titleTematic}</h4>
              <CardTiradas
                tematic={tematic}
                displayCards={displayCards}
                link={labels.downloadLink}
                tirada={tirada}
              />
              <BannerDownload props={labels.downloadLabel} />
            </LinkWrapper>
          </div>
        ) : null}

        {/* 3. CONTENT */}
        {/* ================================================ */}
        {optionsCard?.showTitleInContent && (
          <div style={{ textAlign: 'center', fontSize: '1.35rem', margin: '.6rem 0' }}>
            {titleTematic}
          </div>
        )}

        {optionsCard?.isShowDate && (
          <div style={{ textAlign: 'center', fontSize: '1rem', margin: '.6rem 0', color: '#666' }}>
            {date}
          </div>
        )}

        {optionsCard?.isShowContentQuestion &&
          <div>
            <LinkWrapper link={labels.downloadLink}>
              <div className="questionWrapper">
                {question &&
                  <h4 className="question">{question}</h4>
                }
                <div className="answer">{
                  (totalYes?.length > totalNo?.length) ? 'Sí' : 'No'
                }</div>
              </div>
            </LinkWrapper>

            <CardTiradas
              props={displayCards}
              isQuestion={true}
              link={labels.downloadLink}
            />

            <div className="container">
              <h4>{labels.resultYesOrNotLabel}</h4>
              <p>{labels.resultDescription}</p>
            </div>

            <ContentYesOrNot
              labels={content.labels}
              cards={displayCards}
              link={labels.downloadLink}
            />
          </div>
        }

        {optionsCard?.isShowContentDefault && (
          <div className="container">
            {/* Content */}
            <main>
              {displayCards.map((card, i) => (
                <div key={i}>
                  {
                    tagCards[tirada || displayCards?.length] &&
                    <div style={{ color: '#888', marginTop: '1rem' }}>
                      {tagCards[tirada || displayCards?.length][i]}
                    </div>
                  }
                  <CardItem
                    props={card}
                    labels={labels}
                    tematic={tematic}
                    qtyCards={displayCards.length}
                    link={labels.downloadLink}
                  ></CardItem>
                </div>
              ))
              }
            </main>

            {/* Zodiac Name */}
            <LinkWrapper link={labels.downloadLink}>
              {
                displayZodiac && <ZodiacName label={labels.zodiacLabel} props={displayZodiac}></ZodiacName>
              }
            </LinkWrapper>

          </div>
        )}

        {optionsCard?.isShowContentCombination && (
          <div
            style={{ border: "1px solid #ccc", borderRadius: "8px", margin: "1rem" }}
            className="container"
          >
            <div style={{ textAlign: 'center', fontSize: '1.35rem', margin: '.6rem 0' }}>
              <b>Interpretación</b>
            </div>
            <div style={{ fontSize: '.96rem', margin: '1rem 0' }}>
              <p dangerouslySetInnerHTML={{ __html: combinations && combinations[tematic][card]?.content }}></p>
            </div>
          </div>
        )}

        {/* 4. FOOTER */}
        {/* ================================================ */}
        <LinkWrapper link={labels.downloadLink}>
          <BannerDownload props={labels.downloadLabel} />
          <GooglePlayDownload />
        </LinkWrapper>

      </div>
    </>
  );
}

export default Es;
