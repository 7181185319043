import { doc, getDoc } from "firebase/firestore";
import { database } from '../lib/firebase'

export const getContentFromFirestore = async () => {

  const docRef = doc(database, "es", "web");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
    return null;
  }

}
