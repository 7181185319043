import firebase from 'firebase/compat/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyD6vgKCxLh2MjkJcneBmca4Nfg0pKdYyFg",
  authDomain: "tarot-static-web.firebaseapp.com",
  projectId: "tarot-static-web",
  storageBucket: "tarot-static-web.appspot.com",
}

const app = firebase.initializeApp(firebaseConfig)
const database = getFirestore(app)

export {
  app,
  database,
}