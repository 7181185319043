import React from "react";
import { Route, Routes } from "react-router-dom";
import Es from "../pages/es";
import Index from "../pages";

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path={`/`} element={<Index />} />
      <Route path={`/es`} element={<Es />} />
    </Routes>
  );
};
