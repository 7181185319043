import { Link } from "react-router-dom";

function Index() {
  return (
    <>
      <Link to={"/es"}>Es</Link>
    </>
  );
}

export default Index;
