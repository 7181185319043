import s from './GooglePlayDownload.module.css'
import cn from 'classnames'

const GooglePlayDownload = () => {
  return (
    <div className={cn(s.googlePlayWrapper)}>
      <img
        className={s.googlePlayImage}
        width={180}
        height={69.66}
        alt='Disponible en Google Play'
        src='https://play.google.com/intl/es-419/badges/static/images/badges/es-419_badge_web_generic.png'
      />
    </div>
  )
}
export default GooglePlayDownload
